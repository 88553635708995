import type { PaginationResponse } from '@qcwp/common'
import type { MediaUserDetailResponse, MediaUserRecommendParams, MediaUserRecommendResponse } from './type'
import type { FetchResponseType } from '~~/src/server/request/type'
import { useApiFetch } from '~~/src/server/request'

/**
 * 获取媒体详情
 * @params phone
 * @returns Promise<FetchResponseType<boolean>>
 */
export async function getMediaUserDetail(mediaUserId: string) {
  return useApiFetch<FetchResponseType<MediaUserDetailResponse>>(`/news/portal/mediaUser/detail/${mediaUserId}`, {
    method: 'GET',
  })
}

/**
 * 获取推荐媒体
 * @params phone
 * @returns Promise<FetchResponseType<boolean>>
 */
export async function getMediaUserRecommend(params: MediaUserRecommendParams) {
  return useApiFetch<FetchResponseType<PaginationResponse<MediaUserRecommendResponse[]>>>('/news/portal/mediaUser/recommed', {
    method: 'GET',
    params,
  })
}
