import type { ConfigResponse, DictResponse, FileType, MpQrcodeParams, WechatMpShareConfigResponse } from './types'
import type { FetchResponseType } from '~~/src/server/request/type'
import { useApiFetch } from '~~/src/server/request'

/**
 * 根据参数键名查询参数值
 * @param configKey
 * @returns FetchResponseType<ConfigResponse>
 */
export function getConfigKey(configKey: string) {
  return useApiFetch<FetchResponseType<ConfigResponse>>(`/base/portal/config/configKey/${configKey}`, {
    method: 'GET',
  })
}

/**
 * 根据字典类型查询字典数据信息
 * @param dictType 字典类型 string
 * @returns Promise<_AsyncData<FetchResponseType<DictResponse[]>, FetchError<any> | null>>
 */
export function getDictType(dictType: string) {
  return useApiFetch<FetchResponseType<DictResponse[]>>(`/base/portal/dict/data/type/${dictType}`, {
    method: 'GET',
    setting: {
      repetition: 'pre',
    },
  })
}

export function getSignature(url: string) {
  return useApiFetch<FetchResponseType<WechatMpShareConfigResponse>>('/third/wx/shareConfig', {
    method: 'GET',
    params: {
      url,
      thirdSubject: 'qcwp',
    },
    setting: {
      noPrompts: true, // 错误不提示
    },
  })
}

export function getMpQrcode(params: MpQrcodeParams) {
  return useApiFetch<FetchResponseType<string>>('/third/wx/miniappQRCode', {
    method: 'POST',
    params,
    setting: {
      noPrompts: true, // 错误不提示
    },
  })
}
export async function feedback(params: { content: string }) {
  return useApiFetch<FetchResponseType<boolean>>('/base/portal/suggest', {
    method: 'POST',
    body: params,
    timeout: 0,
  })
}
export async function getLocation() {
  return useApiFetch<FetchResponseType<{ ip: string;proName: string;cityName: string }>>('/base/portal/area/localArea', {
    method: 'GET',
    setting: {
      noPrompts: true, // 错误不提示
    },
  })
}
export async function siteAccess() {
  return useApiFetch<FetchResponseType<boolean>>('/base/portal/log/incAccess', {
    method: 'GET',
    setting: {
      noPrompts: true, // 错误不提示
    },
  })
}
export async function catchUploadFile(params: { code: FileType; file: string }) {
  return useApiFetch<FetchResponseType<string>>('/file/catchUploadBody', {
    method: 'POST',
    body: { ...params },
    timeout: 90000,
  })
}

export async function catchUploadBase64(src: string) {
  return useApiFetch<FetchResponseType<string>>('/file/portal/imgUrlToBase64', {
    method: 'POST',
    body: {
      imgUrl: src,
    },
    timeout: 90000,
  })
}
