import { LRUCache as LRU_CACHE } from 'lru-cache'
import { generateRequestKey } from './core'
import type { NitroFetchContext } from './type'

export const CacheOption = {
  max: 100,
  // how long to live in ms
  ttl: 1000 * 60 * 5,
  allowStale: true,
}
export class CacheModule {
  cacheInstance
  NEED_CACHE_API = [
    '/news/portal/ad/all',
    '/base/portal/dict/data/type',
    '/news/portal/article/recomTitle', // 查询推荐标题
    '/news/portal/article/topList', // 查询榜单列表
    '/news/portal/tags/list',
    '/news/portal/mediaUser/recommed', // 关注媒体
    '/news/portal/column/all', // 获取栏目
    '/news/portal/article/columnArticle',
    '/news/portal/article/list',
    '/news/portal/banner/list',
    '/news/portal/article/recomTitle',
    '/news/portal/mediaUser/recommed',
    '/news/portal/article/recomArticle',
    // '/news/portal/article/search',
    // '/news/portal/live/list',
    '/third/wx/shareConfig',
    '/car/portal/field/all',
    '/car/portal/fieldType/all',
    '/car/portal/dealerModel/list',
    '/car/portal/dealerSeries/all',
    '/car/portal/brand/all',
    '/car/portal/series/all',
    '/car/portal/model/all',
    '/car/portal/dealer/all',
    '/car/portal/series/list',
    '/car/portal/model/value',
    '/car/portal/dealer/list',
    '/car/portal/modelpraiseComment/list',
    '/car/portal/seriesPraiseInfo/list',
    '/car/portal/series/competitors',
    '/car/portal/seriesPraiseInfo/competitors',
    '/car/portal/seriesPraiseInfo',
    '/car/portal/dealerSeries/allProvince',
    '/base/portal/area/localArea',
  ]

  NEED_CACHE_KEYS = [
    '/news/portal/article/list&GET&&hot=1&pageNum=1&pageSize=10&', // 热门推荐资讯
  ]

  EXCLUDE_KEYS: string[] = []

  constructor(options: LRU_CACHE.Options<string, Promise<unknown>, unknown>) {
    this.cacheInstance = new LRU_CACHE(options)
  }

  /**
    *  根据url和params生成缓存key
    * @param args
    * @returns
  */
  generateCacheKey(config: NitroFetchContext) {
    const url = config.request
    return generateRequestKey(url as string, config.options)
  }

  // 判断是否需要缓存
  checkNeedCache(api: string, key: string, _log = false) {
    let isNeed = false
    if (api.match(/\/\d+$/))
      isNeed = this.NEED_CACHE_API.includes(api.replace(/\/\d+$/, ''))

    const isNeedCache = isNeed || (this.NEED_CACHE_API.includes(api) && !this.EXCLUDE_KEYS.includes(key)) || this.NEED_CACHE_KEYS.includes(key)
    return isNeedCache
  }

  fetch(key: string) {
    return this.cacheInstance.fetch(key)
  }
}
