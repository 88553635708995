/* eslint-disable @typescript-eslint/ban-ts-comment */
import { BASE_HOST_WEB } from '@qcwp/common'
import { isClient } from '@qcwp/utils'
import { ApiRequest } from './core'
import { ApiInterceptor } from './interceptors'
import type { FetchResponseType } from './type'
const interceptors = new ApiInterceptor()
const { onRequest, onRequestError, onResponse, onResponseError } = interceptors

export const useApiFetch = ApiRequest({
  baseURL: `${BASE_HOST_WEB}/api`,
  retry: 0,
  headers: {
    'Accept': 'application/json;charset=UTF-8;',
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/json;charset=UTF-8;',
  },
  // ?server side abort  => TypeError: invalid_argument
  timeout: isClient ? 15000 : 3000,
  setting: {
    repetition: 'pre',
  },
  onRequest,
  onRequestError,
  // @ts-expect-error
  onResponse: onResponse<FetchResponseType>,
  onResponseError: onResponseError<FetchResponseType>,
})

/**
 * 刷新令牌，然后再次请求之前的请求
 */
// async function autoRefresh(_ctx: NitroFetchContext & { response: FetchResponse<any> }) {
//   const authStore = useAuth()
//   try {
//     await authStore.refreshLoginStatus()
//     // console.log(_ctx, _ctx.options, _ctx.request)
//     return useApiFetch(
//       _ctx.request as string,
//       { ..._ctx.options, headers: { Authorization: authStore.USER_TOKEN, ...(_ctx.options.headers || {}) } },
//     )
//   }
//   catch (error) {
//     console.error(error)
//     return Promise.reject(error)
//   }
// }
