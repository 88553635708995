/*
 * @Author: fengjiahua fenjiahua@qq.com
 * @Date: 2024-05-22 09:11:33
 * @LastEditors: fengjiahua fenjiahua@qq.com
 * @LastEditTime: 2024-09-10 09:55:05
 * @FilePath: \qcwp-vue\packages\web\src\server\modules\car\index.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import type { OmitPageParam, PaginationResponse } from '@qcwp/common'
import type {
  BrandResponse,
  CarBrandParams,
  CarFieldResponse,
  CarFieldTypeResponse,
  DealerAddXjSjParams,
  DealerAllListParams,
  DealerDetailResponse,
  DealerListParams,
  DealerListResponse,
  DealerModelParams,
  DealerModelResponse,
  DealerSalesParams,
  DealerSalesResponse,
  DealerSeriesAllParams,
  DealerSeriesAllResponse,
  DealerSeriesParams,
  DealerSeriesResponse,
  FilterSeriesParams,
  ModelAllParams,
  ModelFieldParams,
  ModelFieldResponse,
  ModelPraiseParams,
  ModelPraiseResponse,
  ModelResponse,
  SeriesAllResponse,
  SeriesDetailResponse,
  SeriesPraiseDetailResponse,
  SeriesPraiseParams,
  SeriesPraiseResponse,
  SeriesPraiseSelectionResponse,
  SeriesResponse,
  SimplePraiseParams,
  TestDriveApplyListResponse,
  TestDriveResponse,
  VendorResponse,
  searchSeries,
  searchSeriesItem,
} from './types'
import { useApiFetch } from '~/server/request'
import type { FetchResponseType } from '~/server/request/type'

/**********************************************************/
/** ******************* car management ********************/
/**********************************************************/

/**
 * 车系搜索建议
 * @param params
 * @returns
 */
export function searchSeriesSuggestApi(params: { vendorSeriesName: string }) {
  return useApiFetch<FetchResponseType<searchSeriesItem[]>>('/car/portal/series/SearchSuggest', {
    method: 'GET',
    params,
  })
}

export function seriesSearchByNameApi(params: { vendorSeriesName: string }) {
  return useApiFetch<FetchResponseType<searchSeries>>('/car/portal/series/SearchByName', {
    method: 'GET',
    params,
  })
}

/**
 * 查询全部品牌
 * @param params FilterSeriesParams
 * @returns Promise<_AsyncData<FetchResponseType<PaginationResponse<SeriesResponse[]>>, FetchError<any> | null>>
 */
export function getBrandList(params?: CarBrandParams) {
  return useApiFetch<FetchResponseType<BrandResponse[]>>('/car/portal/brand/all', {
    method: 'GET',
    params,
  })
}
/**
 * 查询全部车系
 * @returns Promise<_AsyncData<FetchResponseType<SeriesAllResponse[]>, FetchError<any> | null>>
 */
export function getSeriesAllList() {
  return useApiFetch<FetchResponseType<SeriesAllResponse[]>>('/car/portal/series/all', {
    method: 'GET',
    setting: {
      noPrompts: true,
    },
  })
}
/**
 * 查询品牌全部车系
 * @param params { carBrandId: string }
 * @returns Promise<_AsyncData<FetchResponseType<VendorResponse[]>, FetchError<any> | null>>
 */
export function getSeriesList(params: { carBrandId: string }) {
  return useApiFetch<FetchResponseType<VendorResponse[]>>('/car/portal/brandVendor/all', {
    method: 'GET',
    params,
  })
}
/**
 * 查询车系列表
 * @param params FilterSeriesParams
 * @returns Promise<PaginationResponse<SeriesResponse[]>>
 */
export function getFilterSeries(params: FilterSeriesParams) {
  return useApiFetch<FetchResponseType<PaginationResponse<SeriesResponse[]>>>('/car/portal/series/list', {
    method: 'GET',
    params,
  })
}

/**
 * 获取车系详情
 * @param seriesId 车系id
 */
export function getSeriesDetail(seriesId: string) {
  return useApiFetch<FetchResponseType<SeriesDetailResponse>>(`/car/portal/series/${seriesId}`, {
    method: 'GET',
  })
}

/**
 * 查询全部车型
 * @param params ModelAllParams
 */
export async function getModelAll(params: ModelAllParams) {
  return await useApiFetch<FetchResponseType<ModelResponse[]>>('/car/portal/model/all', {
    method: 'GET',
    params,
  })
}

/**
 * 查询全部汽车字段类型
 */
export function getCarFieldType() {
  return useApiFetch<FetchResponseType<CarFieldTypeResponse[]>>('/car/portal/fieldType/all', {
    method: 'GET',
  })
}

/**
 * 查询全部汽车字段
 */
export function getCarField() {
  return useApiFetch<FetchResponseType<CarFieldResponse[]>>('/car/portal/field/all', {
    method: 'GET',
  })
}

/**
 * 查询车型全部参数
 * @param params ModelAllParams
 */
export async function getModelField(params: ModelFieldParams) {
  return await useApiFetch<FetchResponseType<ModelFieldResponse[]>>('/car/portal/model/value', {
    method: 'GET',
    params,
  })
}

/**********************************************************/
/** ****************** dealer management ******************/
/**********************************************************/
export function getDealerAllList(params: DealerAllListParams) {
  return useApiFetch<FetchResponseType<DealerListResponse[]>>('/car/portal/dealer/all', {
    method: 'GET',
    params,
  })
}
export function getDealerList(params: DealerListParams) {
  return useApiFetch<FetchResponseType<PaginationResponse<DealerListResponse[]>>>('/car/portal/dealer/list', {
    method: 'GET',
    params,
  })
}

/**
 * 获取经销商详情
 * @param dealerId 经销商id
 */
export function getDealerDetail(dealerId: string) {
  return useApiFetch<FetchResponseType<DealerDetailResponse>>(`/car/portal/dealer/${dealerId}`, {
    method: 'GET',
  })
}

/**
 * 查询经销商职员列表
 * @params DealerSalesParams
 */
export function getDealerSales(params: DealerSalesParams) {
  return useApiFetch<FetchResponseType<PaginationResponse<DealerSalesResponse[]>>>('/car/portal/dealerStaff/list', {
    method: 'GET',
    params,
  })
}

/**
 * 查询经销商车型列表
 * @params DealerModelParams
 */
export function getDealerModel(params: DealerModelParams) {
  return useApiFetch<FetchResponseType<PaginationResponse<DealerModelResponse[]>>>('/car/portal/dealerModel/list', {
    method: 'GET',
    params,
  })
}

export function getDealerModelAll(params: OmitPageParam<DealerModelParams>) {
  return useApiFetch<FetchResponseType<DealerModelResponse[]>>('/car/portal/dealerModel/all', {
    method: 'GET',
    params,
  })
}

/**
 * 查询经销商车系列表
 * @params DealerModelParams
 */
export function getDealerSeries(params: DealerSeriesParams) {
  return useApiFetch<FetchResponseType<PaginationResponse<DealerSeriesResponse[]>>>('/car/portal/dealerSeries/list', {
    method: 'GET',
    params,
  })
}

/**
 * 经销商经营全部车系
 * @params DealerSeriesAllParams
 */
export function getDealerSeriesAll(params: DealerSeriesAllParams) {
  return useApiFetch<FetchResponseType<DealerSeriesAllResponse[]>>('/car/portal/dealerSeries/all', {
    method: 'GET',
    params,
  })
}

export function addXjSj(params: DealerAddXjSjParams) {
  return useApiFetch<FetchResponseType<boolean>>('/car/portal/testdrive', {
    method: 'POST',
    body: { ...params },
  })
}

/**
 * 口碑
 */
export function getReputationAsyncList() {
  // return useApiFetch<FetchResponseType<any[]>>('/car/portal/reputation/all', {
  //   method: 'GET',
  // })
}

/**
 * 车系竞品列表
 */
export function getSeriesCompetitors(carSeriesId: string) {
  return useApiFetch<FetchResponseType<SeriesDetailResponse[]>>('/car/portal/series/competitors', {
    method: 'GET',
    params: { carSeriesId },
  })
}

/**
 * 车系口碑信息列表
 * 默认按综合评分排序
 */
export function getSeriesPraise(params: SeriesPraiseParams) {
  return useApiFetch<FetchResponseType<PaginationResponse<SeriesPraiseResponse[]>>>('/car/portal/seriesPraiseInfo/list', {
    method: 'GET',
    params,
  })
}

/**
 * 车系精选口碑列表
 */
export function getSeriesPraiseSelection() {
  return useApiFetch<FetchResponseType<SeriesPraiseSelectionResponse[]>>('/car/portal/seriesPraiseInfo/choicenessList', {
    method: 'GET',
  })
}
/**
 * 查询车系口碑信息
 */
export function getSeriesPraiseDetail(seriesId: string) {
  return useApiFetch<FetchResponseType<SeriesPraiseDetailResponse>>(`/car/portal/seriesPraiseInfo/${seriesId}`, {
    method: 'GET',
  })
}
/**
 * 查询车系竞品口碑列表
 */
export function getSeriesPraiseCompetitors(seriesId: string) {
  return useApiFetch<FetchResponseType<SeriesPraiseResponse[]>>('/car/portal/seriesPraiseInfo/competitors', {
    method: 'GET',
    params: { carSeriesId: seriesId },
  })
}

/**
 * 查询车型口碑信息列表
 */
export function getModelPraise(params: ModelPraiseParams) {
  return useApiFetch<FetchResponseType<PaginationResponse<ModelPraiseResponse[]>>>('/car/portal/modelpraiseComment/list', {
    method: 'GET',
    params,
  })
}

/**
 * 获取车系的经销商地区分布
 */
export function getSeriesDealerProvince(carSeriesId: string) {
  return useApiFetch<FetchResponseType<string[]>>(`/car/portal/dealerSeries/allProvince/${carSeriesId}`, {
    method: 'GET',
  })
}

export function publishSimplePraise(params: SimplePraiseParams) {
  return useApiFetch<FetchResponseType<boolean>>('/car/user/modelpraiseComment/short', {
    method: 'POST',
    body: { ...params },
  })
}

export function getTestDrive(id: string) {
  return useApiFetch<FetchResponseType<TestDriveResponse>>(`/car/portal/testdriveInvite/${id}`, {
    method: 'GET',
  })
}

export function getTestDriveApplyList(ids: string[]) {
  return useApiFetch<FetchResponseType<TestDriveApplyListResponse[]>>('/car/portal/testdrive/getTestdrive', {
    method: 'GET',
    params: { ids: ids.join(',') },
  })
}
