import type { _AsyncData } from 'nuxt/dist/app/composables/asyncData'
import { PaginationStatus, usePagination } from '@qcwp/common'
import type { FetchResponseType, PaginationOption, PaginationParams, PaginationResponse } from '@qcwp/common'
import { useAsyncData } from '#app'

export type PaginationNuxtFn = ReturnType<typeof usePaginationNuxt>
/**
 * 分页
 * TODO: 上拉请求 | 页码请求
 */
export function usePaginationNuxt(option?: PaginationOption) {
  const {
    setPage,
    loadType,
    pagination,
    initPagination,
    setPagination,
    setPaginationStatus,
    canLoadMore,
    loadPaginationData,
    
  } = usePagination(option)
  async function loadPaginationAsyncData<P extends PaginationParams = PaginationParams, R = unknown>(
    key: string,
    request: (params: P) => Promise<FetchResponseType<PaginationResponse<R>>>,
    params: Omit<P, 'pageNum' | 'pageSize'> & Partial<Pick<P, 'pageNum' | 'pageSize'>>,
    page?: number,
  ): Promise<_AsyncData<FetchResponseType<PaginationResponse<R>>, Error | null> | null> {
    // 如果有页码传递进来，设置页码
    if (loadType !== 'up')
      page && setPage(page)
    // 如果是上拉加载，当加载错误是不修改当前页码
    else if (pagination.status !== PaginationStatus.ERROR)
      page && setPage(page)

    // console.log('pagination.status', pagination.status, PaginationStatus.ERROR)
    if (!canLoadMore())
      // return Promise.reject(new Error(`不能加载更多数了(${pagination.value.status})`))
      return null

    const noAddPageCount = loadType !== 'up' || [PaginationStatus.INIT].includes(pagination.status)
    try {
      const page = noAddPageCount ? pagination.page : pagination.page + 1
      // 设置 loading 状态
      setPaginationStatus(PaginationStatus.LOADING)
      // 发送请求

      const asyncData = await useAsyncData(key, () => request({ pageSize: pagination.pageSize, ...params, pageNum: page } as P))
      const { data: response, error, refresh } = asyncData
      if (error.value) {
        await refresh?.({ dedupe: true })
        if (error.value !== null)
          throw error.value
      }
      if (!response.value)
        return null
      const { data } = response.value
      // 设置分页状态
      setPaginationStatus(data.pageNum < data.pages ? PaginationStatus.MORE : PaginationStatus.NO_MORE)
      // 设置分页具体信息
      setPagination(data)

      return asyncData
    }
    catch (error) {
      // console.error('loadPaginationData error: ', error)
      setPaginationStatus(PaginationStatus.ERROR)
      return Promise.reject(error)
    }
  }

  return {
    loadType,
    pagination,
    initPagination,
    setPage,
    setPagination,
    setPaginationStatus,
    canLoadMore, // 判断是否能够加载更多
    loadPaginationData,
    loadPaginationAsyncData,
  }
}
