import type { AdResponse, GetAdParams } from './types'
import { useApiFetch } from '~~/src/server/request'
import type { FetchResponseType } from '~~/src/server/request/type'
/**
 * 获取媒体详情
 * @params phone
 * @returns Promise<FetchResponseType<boolean>>
 */
export async function getPageAds(params?: GetAdParams) {
  return useApiFetch<FetchResponseType<AdResponse[]>>('/news/portal/ad/all', {
    method: 'GET',
    params,
    setting: {
      noPrompts: true,
    },
  })
}
