import type { ToastType } from 'vant/es'
import { goLoginPage } from '@qcwp/common'
import { isClient } from '@qcwp/utils'

export function toast(message: string) {
  vantToast({
    type: 'text',
    duration: 1000,
    message,
  })
}

/**
 * 请求等待和结果提示
 * @param isLoading 是否显示loading：true| false
 * @param errorType 错误时提示的类型：'toast' | 'dialog'
 * @param tips 默认提示文本
 * @returns
 */
export function useToast(isLoading = false, errorType: 'toast' | 'dialog', tips: {
  title: string
  loading: string
  success: string
  showType?: 'toast' | 'dialog'
} = {
  title: '',
  loading: '加载中',
  success: '加载成功',
  showType: 'toast',
}) {
  const toastType = ref<ToastType>('success')
  const toastText = ref(tips.success)
  const loadingToast = isLoading && vantToast.loading({
    duration: 0,
    message: tips.loading,
    forbidClick: true,
  })

  function setToast(text: string, type: ToastType = 'fail') {
    toastType.value = type
    toastText.value = text
  }

  function showToast(clear = false) {
    loadingToast && loadingToast.clear()
    if (clear)
      return
    if (toastType.value === 'fail' && errorType === 'dialog') {
      toastText.value.includes('还未登录')
        ? useNoLoginVantDialog(toastText.value)
        : vantDialog({ title: tips.title, message: toastText.value })
    }
    else if (tips.showType === 'dialog') {
      vantDialog({
        title: tips.success,
        message: toastText.value,
      })
    }
    else {
      vantToast({
        type: toastType.value,
        duration: 1000,
        message: toastText.value,
      })
    }
  }

  return {
    toastType,
    toastText,
    loadingToast,
    setToast,
    showToast,
  }
}

export function useLoginToast() {
  return useToast(true, 'dialog', {
    title: '登录失败',
    loading: '正在登录...',
    success: '登录成功',
  })
}

/**
 *
 * @param message
 * @param options type: 'dialog' | 'popup' 默认dialog | redirect: 登录成功后跳转的地址
 * popup: 通过弹窗登录，登录成功后关闭弹窗
 * dialog: dialog提示登录，确认后跳转到登录页
 *
 */
export async function useNoLoginVantDialog(message = '还未登录，请先登录', options: {
  type?: 'dialog' | 'popup'
} = {}) {
  const { type = 'dialog' } = options
  try {
    vantToast.clear()
    vantDialog.close()
    if (type === 'dialog') {
      await vantDialog.confirm({ message })
      const query = isClient
        ? { redirect: window.location.href }
        : {}
      goLoginPage(query)
    }
    else {
      const $bus = useNuxtApp().$bus as any
      vantToast({
        message,
      })
      $bus.emit('SHOW_LOGIN', {
        show: true,
        redirect: window.location.href,
      })
    }
  }
  catch { }
}
