import type { BANNER_DATA_TYPE, TimeString } from '@qcwp/common'
import type { ArticlePagesResponse } from '../article/type'

export enum AD_KEYS {
  HOME = '1',
  NEWS_HOME = '2',
  SERIES_DETAIL = '3',
  NEWS_DETAIL = '4',
  MEDIA_HOME = '5',
  DEALER = '6',
}
export enum AD_POSITION {
  'A1' = 'A1',
  'A2' = 'A2',
  'A3' = 'A3',
  'A4' = 'A4',
  'A5' = 'A5',
  'A6' = 'A6',
  'A7' = 'A7',
  'A8' = 'A8',
  'A9' = 'A9',
  'A10' = 'A10',
}
export interface GetAdParams extends Partial<AdResponse> {

}

export interface AdResponse {
  adLink: string
  adLocation: AD_POSITION
  adStartTime: string
  adEndTime: string
  adStatus: '0' | '1'
  adTitle: string
  adType: AD_KEYS
  appImgSize: string
  appImgUrl: string
  createTime: TimeString
  id: string
  pcImgSize: string
  pcImgUrl: string
  remark: string | null
  adDataType: BANNER_DATA_TYPE
  adDataId: string
  dataArticleVO?: null | ArticlePagesResponse
}
