/**
 * 对于fetch请求的封装
 */
import qs from 'qs'
import type { FetchResponse } from 'ofetch'
import type { NitroFetchOptions, NitroFetchRequest, TypedInternalResponse } from 'nitropack'
import { isClient, isString } from '@qcwp/utils'
import type { GetBodyType, NitroFetchContext, PostBodyType, ServerRequestOptions } from './type'
import { CacheModule, CacheOption } from './cache'

export type PromiseFn = (...args: any[]) => Promise<unknown>
// 使用nuxt的$fetch
function ServerRequest(options: NitroFetchOptions<NitroFetchRequest>) {
  function get<T extends GetBodyType = Record<string, string | number>, R = unknown>(url: string, query?: T, opt?: NitroFetchOptions<NitroFetchRequest>) {
    // Object.assign会修改target(也就是第一个参数)，所以使用一个空对象
    // 如果不使用一个空对象会导致 option 的原值被覆盖
    const _opt: NitroFetchOptions<NitroFetchRequest> = opt ? Object.assign({}, options, opt) : options
    return $fetch<R>(url, {
      ..._opt,
      method: 'GET',
      params: query,
    })
  }
  function post<T extends PostBodyType = Record<string, string | number>, R = unknown>(url: string, data?: T, opt?: NitroFetchOptions<NitroFetchRequest>) {
    const _opt: NitroFetchOptions<NitroFetchRequest> = opt ? Object.assign({}, options, opt) : options
    return $fetch<R>(url, {
      ..._opt,
      method: 'POST',
      body: data,
    })
  }

  function instance<R = unknown>(opt: ServerRequestOptions): Promise<TypedInternalResponse<NitroFetchRequest, R>>
  function instance<R = unknown>(url: string, opt?: ServerRequestOptions): Promise<TypedInternalResponse<NitroFetchRequest, R>>
  function instance<R = unknown>(arg1: string | ServerRequestOptions, arg2?: ServerRequestOptions) {
    const _url = isString(arg1) ? arg1 : arg1?.url
    const _opt = isString(arg1) ? arg2 : arg1
    const _config: NitroFetchOptions<NitroFetchRequest> = _opt ? Object.assign({}, options, _opt) : options

    if (!_url)
      throw new Error('请求地址不能为空')

    return $fetch<R>(_url, {
      ..._config,
    })
  }
  /**
   * 如果需要获取原响应
   */
  function raw<R = unknown>(url: string, opt?: NitroFetchOptions<NitroFetchRequest>) {
    const _opt: NitroFetchOptions<NitroFetchRequest> = opt ? Object.assign({}, options, opt) : options

    return $fetch.raw<R>(url, {
      ..._opt,
    })
  }

  instance.get = get
  instance.post = post
  instance.raw = raw

  return instance
}

// 对重复请求处理进行封装
export function ApiRequest(options: NitroFetchOptions<NitroFetchRequest>) {
  /**
   * 中止请求在拦截器中实现
   */
  const onRequest = async (_ctx: NitroFetchContext) => {
    return options.onRequest?.(_ctx)
  }
  const onRequestError = async (_ctx: NitroFetchContext & { error: Error }) => {
    return options.onRequestError?.(_ctx)
  }
  const onResponse = async <R = any>(_ctx: NitroFetchContext & { response: FetchResponse<R> }) => {
    return options.onResponse?.(_ctx)
  }

  // 创建请求实例
  const request = ServerRequest({
    ...options,
    onRequest,
    onRequestError,
    onResponse,
  })

  // use lru-cache
  const CACHE = new CacheModule(CacheOption)

  const API_PROMISE_MAP = CACHE.cacheInstance
  if (isClient)
    // @ts-expect-errors eslint-disable-line @typescript-eslint/ban-ts-comment
    window.API_PROMISE_MAP = API_PROMISE_MAP

  /**
   * 代理请求，以在请求执行前进行一些操作
   */
  function requestPromiseHandle(self: any, args: any, target: PromiseFn, key: { requestKey: string; url: string }) {
    // 是否有对应的正在执行的请求
    // 如果有则直接返回对应的promise
    if (API_PROMISE_MAP.has(key.requestKey))
      return API_PROMISE_MAP.get(key.requestKey)

    // 执行
    const requestPromise = target.apply(self, args)
    requestPromise.catch?.((error: any) => {
      // 请求错误时移除对应请求promise
      API_PROMISE_MAP.delete(key.requestKey)
      throw error
    }).finally(() => {
      // 对于特定的请求不移除 cache && 只在客户端缓存
      if (!isClient || !CACHE.checkNeedCache(key.url, key.requestKey))
        API_PROMISE_MAP.delete(key.requestKey)
    })

    // 保存对应请求promise
    API_PROMISE_MAP.set(key.requestKey, requestPromise)
    // 返回请求promise
    return requestPromise
  }
  function serverRequestProxyHandle(target: PromiseFn, that: unknown, args: [opt: ServerRequestOptions]): any
  function serverRequestProxyHandle(target: PromiseFn, that: unknown, args: [url: string, opt?: ServerRequestOptions]): any
  function serverRequestProxyHandle(this: any, target: PromiseFn, that: unknown, args: [arg1: string | ServerRequestOptions, arg2?: ServerRequestOptions, arg3?: ServerRequestOptions]): any {
    const _url = (isString(args[0]) ? args[0] : args[1]?.url) || ''
    const _opt = (isString(args[0]) ? args[1] : args[0]) || {}

    const requestKey = generateRequestKey(_url, _opt)

    const repetition = _opt?.setting?.repetition || options.setting?.repetition
    // 是否不是返回之前发送且还在请求的请求promise
    if ((!isClient || !CACHE?.checkNeedCache(_url, requestKey, true)) && repetition !== 'pre')
      return target.apply(this, args)

    return requestPromiseHandle(this, args, target, { requestKey, url: _url })
  }

  function serverRequestMethodProxyHandle(this: any, target: PromiseFn, that: unknown, args: [url: string, data: Record<string, unknown>, opt?: ServerRequestOptions]): any {
    const _url = args[0]
    const _data = args[1]
    const _opt = args[2] || {}

    const requestKey = generateRequestKey(_url, _opt, _data)
    if (_opt?.setting?.repetition !== 'pre')
      return target.apply(this, args)

    return requestPromiseHandle(this, args, target, { requestKey, url: _url })
  }

  const instance = new Proxy(request, {
    apply: serverRequestProxyHandle,
  }) as typeof request

  instance.get = new Proxy(request.get, {
    apply: serverRequestMethodProxyHandle,
  }) as typeof request.get

  instance.post = new Proxy(request.post, {
    apply: serverRequestMethodProxyHandle,
  }) as typeof request.post

  instance.raw = new Proxy(request.raw, {
    apply: serverRequestProxyHandle,
  }) as typeof request.raw

  return instance
}

// 生成request的唯一key
export function generateRequestKey(url: RequestInfo, options: NitroFetchOptions<NitroFetchRequest>, data?: Record<string, unknown>) {
  // 通过url，method，params，data生成唯一key，用于判断是否重复请求
  // params为get请求参数，data为post请求参数
  let { method, body, params, setting, baseURL = '' } = options
  // url = typeof url !== 'string'
  //   ? url
  //   : Object.keys(params || {}).length === 0
  //     ? url
  //     : url.replace(baseURL, '').split('?')[0]

  // 注意请求发送前就已经拼接过的参数不需要再次拼接
  if (typeof url === 'string') {
    const p = qs.parse(url.split('?')[1])
    params = Object.assign({}, p, params)
    url = url.replace(baseURL, '').split('?')[0]
  }
  // console.log('generateRequestKey', url, method, params, body)
  let key
  if (setting?.splicingParameters !== false) {
    if (method === 'GET' && data && (!params || Object.keys(params).length === 0))
      params = data
    else if (['POST', 'DELETE', 'PUT'].includes(method!) && data && (!body || Object.keys(body).length === 0))
      body = data

    key = [url, method, qs.stringify(params), qs.stringify(body)].join('&')
  }
  else {
    key = [url, method].join('&')
  }

  return key
}
