import { isBoolean, isClient, isValKey } from '@qcwp/utils'
import type { FetchResponseType } from '../server/request/type'
export type BaseErrorType = PromiseRejectionEvent | FetchResponseType | ErrorEvent

export function recordError(_error: unknown) {
  isClient && console.error(_error)
}

export function getErrorMsg(error: any, defaultMsg = '未知错误(code:40000)'): string {
  let msg = ''
  if (isValKey<FetchResponseType>(error, 'msg')) {
    msg = error.msg
  }
  else if (error?.message) {
    try {
      msg = getErrorMsg(JSON.parse(error.message))
    }
    catch (err) {
      msg = error.message
      // console.error('getErrorMsg error JSON.parse', err)
    }
  }
  else if (isValKey<PromiseRejectionEvent>(error, 'reason')) {
    msg = error.reason?.stack || JSON.stringify(error.reason)
  }

  if (!msg) {
    msg = error?.message
      ? error?.message.toString()
      : JSON.stringify(error) !== '{}' ? JSON.stringify(error) : defaultMsg
  }
  if (msg.match('Failed to fetch') || msg.match('Network Error'))
    return '网络发生了故障，请检测网络后重试'
  else if (msg.match(/Aborted/i))
    return '网络请求超时，请稍后重试'

  return msg
}

export function showDialogError(error: any) {
  const msg = getErrorMsg(error)
  if (msg.match('Error occurred while trying to proxy')) {
    vantDialog({
      title: '网络故障',
      message: '网络发生了故障，请检测网络后重试',
    })
  }
  else if (msg?.match('服务未找到') || msg.includes('code: -1')) {
    vantDialog({
      title: '服务器异常',
      message: '服务器发生了错误,请重试',
    })
  }
  else if (msg?.match('The operation was aborted') || msg?.match('The user aborted a request')) {
    vantDialog({
      title: '数据加载失败',
      message: '数据加载超时，请稍后重试',
    })
  }
  else {
    vantDialog({
      title: '错误',
      message: msg,
    })
  }
}
export function nuxtAsyncErrorHandle(
  error: any,
  refresh?: (opt?: { _initial?: boolean; dedupe?: boolean }) => Promise<void>,
  opt?: { _initial?: boolean; dedupe?: boolean },
) {
  if (isBoolean(error) && refresh)
    refresh(opt)
  else
    recordError(error)
}

/**
 * 异步请求错误收集
 * @param fn 异步请求函数
 * @param isThrowError 是否抛出错误
 */
export async function fetchErrorCollect<T>(fn: () => Promise<T>, isThrowError = false) {
  try {
    return await fn()
  }
  catch (error) {
    recordError(error)
    if (isThrowError)
      throw error
    else
      return null
  }
}
