import type { UnwrapNestedRefs } from 'vue'
import type {
  ListState,
} from '@qcwp/common'
import {
  getPaginationListState,
} from '@qcwp/common'
import type {
  AddArticleCommentParams,
  AddArticleCommentResponse,
  ArticleCommentParams,
  ArticleCommentResponse,
} from '../type'
import { articleApi } from '~~/src/server'
import type { PaginationNuxtFn } from '~~/src/composables/usePaginationNuxt'

/**
 * 评论
 */
interface Reply {
  list: UnwrapNestedRefs<Comment[]>
  state: ListState
  pagination: PaginationNuxtFn
  getData: () => Promise<void>
}
export class CommentItem implements ArticleCommentResponse {
  id = ''
  articleId = ''
  replyCount = '0'
  provinceName = ''
  cityName = ''
  content = ''
  level = '1'
  mediaAvatar = ''
  mediaName = ''
  mediaUserId = ''
  createTime = ''
  remark = ''
  toMediaAvatar = ''
  toMediaName = ''
  toMediaUserId = ''
  toCommentId = ''
  constructor(data?: ArticleCommentResponse) {
    if (!data)
      return
    this.id = data.id
    this.articleId = data.articleId
    this.replyCount = data.replyCount || '0'
    this.provinceName = data.provinceName || ''
    this.cityName = data.cityName || ''
    this.content = data.content
    this.level = data.level
    this.mediaAvatar = data.mediaAvatar || ''
    this.mediaName = data.mediaName || ''
    this.mediaUserId = data.mediaUserId
    this.createTime = data.createTime
    this.remark = data.remark || ''
    this.toMediaAvatar = data.toMediaAvatar || ''
    this.toMediaName = data.toMediaName || ''
    this.toMediaUserId = data.toMediaUserId || ''
    this.toCommentId = data.toCommentId || ''
  }
}
export class Comment extends CommentItem {
  reply: Reply | null = null
  showReply = false
  constructor(data?: ArticleCommentResponse) {
    super(data)
    if (data?.replyCount)
      this.reply = this.replyInit()
  }

  /**
   * 初始化评论子列表
   */
  replyInit(): Reply {
    const { list, state, pagination, getData } = useCommentListPages({ articleId: this.articleId, parentId: this.id, level: 2 })
    return {
      list,
      state: state as unknown as ListState, // ComputedRef<ListState>
      pagination,
      getData,
    }
  }

  /**
   * 改变评论子列表显示状态
   */
  changeShowReply() {
    this.showReply = !this.showReply
    if (this.showReply && this.reply && !this.reply.list.length)
      this.reply.getData()
  }
}
export function useComment() {
  async function sendComment(data: AddArticleCommentParams): Promise<false | AddArticleCommentResponse | undefined> {
    if (!data.content) {
      vantDialog({ message: '请输入评论内容' })
      return false
    }

    if (!data.articleId)
      throw new Error('comment article id is undefined')

    const { setToast, showToast } = useToast(true, 'dialog', { title: '评论发布失败', loading: '评论中...', success: '评论发布成功' })
    try {
      const { code, data: responseData } = await articleApi.addArticleComment(data)
      if (code !== 200)
        return false
      else
        return responseData
    }
    catch (error) {
      console.error('[error] useComment sendComment function:', error)
      setToast(getErrorMsg(error), 'fail')
      recordError(error)
    }
    finally {
      showToast()
    }
  }

  return {
    sendComment,
  }
}
export function useCommentListPages(params: Omit<ArticleCommentParams, 'pageNum' | 'pageSize'>) {
  const list = reactive<Comment[]>([])
  const mediaNameMap = reactive<Map<string, string>>(new Map())
  const pagination = usePaginationNuxt()
  const state = computed<ListState>(() => getPaginationListState(pagination.pagination.status))
  async function getData() {
    try {
      const res = await pagination.loadPaginationData(articleApi.getArticleComment, params)
      if (res && res.list && res.list.length) {
        for (const item of res.list) {
          list.push(new Comment(item))
          if (item.mediaUserId && !mediaNameMap.has(item.mediaUserId))
            mediaNameMap.set(item.mediaUserId, item.mediaName || '匿名用户')
        }
      }
    }
    catch (error) {
      console.error('[error] useArticleListComponent articleApi.getArticleList error:', error)
      recordError(error)
    }
  }

  async function initHandle() {
    list.splice(0, list.length)
    pagination.initPagination()
    await getData()
  }

  return {
    list,
    mediaNameMap,
    state,
    pagination,
    getData,
    initHandle,
  }
}
