import { v4 as uuidv4 } from 'uuid'
import { isClient } from '@qcwp/utils'
import type { NitroFetchContext } from './type'
import { generateRequestKey } from './core'

const TIMEOUT_KEYS = new Map<string, NodeJS.Timeout>()
/**
 * 取消重复请求
 */
export function useAbort() {
  const abortPrePageKeys: string[] = []
  // 用于存储pending的请求
  const pendingRequest = new Map<string, { controller: AbortController;timeoutKey: string }>()
  const extendsApi = [
    '/base/portal/verifycode',
    '/third/wx/open/qrcode',
    '/news/portal/mediaUser/list',
    '/auth/portal',
    '/news/user/mediaUser/info',
  ]

  function addPendingRequest(config: NitroFetchContext) {
    const key = generateRequestKey(config.request, config.options)

    const url = config.request as string
    // console.log('addPendingRequest', key, url, extendsApi.some(api => url?.includes(api)))
    // 判断pendingRequest中是否存在key
    if (pendingRequest.has(key) || extendsApi.some(api => url?.includes(api)))
      return

    // https://developer.mozilla.org/zh-CN/docs/Web/API/AbortController/AbortController
    const controller = new AbortController()
    config.options.signal = controller.signal

    let timeoutKey: NodeJS.Timeout | null = null
    if (config.options.timeout) {
      timeoutKey = setTimeout(() => {
        !controller.signal.aborted && controller.abort(
          isClient
            ? new DOMException('请求超时', 'AbortPrePageError')
            : new Error('请求超时(IN_SERVER)'),
        )
        timeoutKey && clearTimeout(timeoutKey)
      }, config.options.timeout)
      // console.log('config.options.timeout', key, config.options.timeout)
    }
    const uuid = timeoutKey ? uuidv4() : ''
    uuid && timeoutKey && TIMEOUT_KEYS.set(uuid, timeoutKey)
    pendingRequest.set(key, { controller, timeoutKey: uuid })
  }

  // removePendingRequest 取消重复请求
  function removePendingRequest(config: NitroFetchContext, type: 'abort' | 'clear' = 'clear') {
    const key = generateRequestKey(config.request, config.options)
    // console.log(key, pendingRequest.has(key))
    if (!pendingRequest.has(key))
      return

    const { controller, timeoutKey } = pendingRequest.get(key) || {}

    if (timeoutKey) {
      const key = TIMEOUT_KEYS.get(timeoutKey)
      key && clearTimeout(key)
    }
    if (type === 'abort') {
      controller && !controller.signal.aborted && controller.abort(
        isClient
          ? new DOMException('终止重复请求', 'AbortError')
          : new Error('终止重复请求'),
      )
    }
    pendingRequest.delete(key) // 请求对象中删除requestKey
  }

  return {
    abortPrePageKeys,
    pendingRequest,
    addPendingRequest,
    removePendingRequest,
  }
}
