import type { FetchResponse } from 'ofetch'
import { storeToRefs } from 'pinia'
import { isClient, isFunction, isNumber, isObject } from '@qcwp/utils'
import { goAnyPage, loginPath, registerPath } from '@qcwp/common'
import { v4 as uuidv4 } from 'uuid'
import type { FetchResponseType, NitroFetchContext } from './type'
import { generateRequestKey } from './core'
import { useAuth, useThird } from '~/store/auth'
import type { useAbort } from '~~/src/server/request/abort'
import type { NuxtApp } from '#app'

function bindingMessage() {
  return h('p', {}, [
    h('span', {}, { default: () => '当前第三方未绑定任何账号/媒体号，' }),
    h('span', { class: 'font-bold' }, { default: () => '注册新账号进行绑定' }),
    h('span', {}, { default: () => '或' }),
    h('span', { class: 'font-bold' }, { default: () => '登录已有账号自动绑定' }),
  ])
}

type UUIDString = string
export class ApiInterceptor {
  localErrorStatus: number[]
  // errorStatus
  errorStatus: number[]
  updateStatus: number[]
  // 成功code
  successCode: number[]
  // 失败code
  errorCode: number[]
  // 需要展示提示的code
  showModel: number[]
  // 是否刷新过token
  isReference: boolean
  // 是否全局错误
  isGlobalError: boolean

  // autoRefresh: (ctx: NitroFetchContext & { response: FetchResponse<any> }) => Promise<unknown>

  nuxtAppMap = new Map<string, NuxtApp>()
  constructor() {
    this.localErrorStatus = [504]
    this.errorStatus = [500]
    this.updateStatus = [520, 510]
    this.successCode = [200]
    // -1 || 未知 = 操作失败
    // -2 = 操作警告
    // -3 = 操作提示
    this.errorCode = [-1, 400, 401, 402, 403, 431]
    this.showModel = [-2, -3]
    this.isReference = false
    this.isGlobalError = false

    // this.autoRefresh = data.autoRefresh
  }

  protected getCurrentNuxtApp(key: UUIDString) {
    let nuxtApp: NuxtApp | null
    // console.log('getCurrentNuxtApp', key, this.nuxtAppMap.keys())
    if (this.nuxtAppMap.has(key)) {
      nuxtApp = this.nuxtAppMap.get(key) as NuxtApp
    }
    else {
      try {
        nuxtApp = useNuxtApp()
        this.setCurrentNuxtApp(key, nuxtApp)
      }
      catch {
        nuxtApp = null
      }
    }
    return nuxtApp
  }

  protected setCurrentNuxtApp(key: UUIDString, nuxtApp: NuxtApp) {
    this.nuxtAppMap.set(key, nuxtApp)
  }

  protected removeCurrentNuxtApp(key: UUIDString) {
    this.nuxtAppMap.delete(key)
  }

  protected setHeaderUUID(headers: Headers): UUIDString {
    const uuid = uuidv4()

    // @ts-expect-error @typescript-eslint/ban-ts-comment
    headers['X-Request-Id'] = uuid

    return uuid
  }

  protected getHeaderUUID(headers: Headers): UUIDString | undefined {
    if (!headers.has || !isFunction(headers.has))
      // @ts-expect-error @typescript-eslint/ban-ts-comment
      return headers['X-Request-Id'] || headers['x-request-id'] || ''
    else if (headers.has('X-Request-Id') || headers.has('x-request-id'))
      return headers.get('X-Request-Id') || headers.get('x-request-id') || ''
  }

  protected timeout(_ctx: NitroFetchContext): boolean {
    return Boolean(_ctx.options?.timeout && isNumber(_ctx.options?.timeout) && _ctx.options?.timeout > 0)
  }

  /**
   * 请求拦截器
   * @param _ctx NitroFetchContext
   */
  onRequest = async (_ctx: NitroFetchContext): Promise<void> => {
    // set uuid
    const uuid = this.setHeaderUUID(_ctx.options.headers as Headers)
    try {
      const nuxtApp = useNuxtApp()
      this.setCurrentNuxtApp(uuid, nuxtApp)
      // 处理重复请求
      if (nuxtApp && (_ctx.options?.setting?.repetition === 'abort' || this.timeout(_ctx))) {
        const { removePendingRequest, addPendingRequest } = nuxtApp.$useAbort as ReturnType<typeof useAbort>
        removePendingRequest(_ctx, 'abort')
        addPendingRequest(_ctx)
      }
    }
    catch (e) {
      console.error('onRequest', e)
    }

    // console.log('onRequest:', useNuxtApp())
    const { USER_TOKEN } = storeToRefs(useAuth())
    // 设置token
    if (USER_TOKEN.value)
      _ctx.options.headers = { Authorization: USER_TOKEN.value, ...(_ctx.options.headers || {}) }

    // 服务端请求设置客户端ip
    if (!isClient)
      _ctx.options.headers = this.setClientHeaders(_ctx.options.headers)
  }

  /**
   * 请求错误拦截
   * ❗不知道什么情况触发onRequestError
   * 已知触发条件👇：
   * 1.请求中断时会被触发
   * @param _ctx NitroFetchContext
 */
  onRequestError = async (_ctx: NitroFetchContext & { error: Error }): Promise<void> => {
    const uuid = this.getHeaderUUID(_ctx.options.headers as Headers)
    if (uuid) {
      const nuxtApp = this.getCurrentNuxtApp(uuid)
      this.removeCurrentNuxtApp(uuid)

      if (_ctx.error && _ctx.error?.name === 'AbortError' && nuxtApp) {
        // 如果是中止请求，则不自动重试
        _ctx.options.retry = 0
        try {
          const { abortPrePageKeys } = nuxtApp.$useAbort as ReturnType<typeof useAbort>
          // console.log('abortPrePageKeys', abortPrePageKeys, key)
          // 如果是跳转页面时中止请求，则不提示
          const key = generateRequestKey(_ctx.request as string, _ctx.options)
          if (abortPrePageKeys.includes(key)) {
            _ctx.options.setting ? (_ctx.options.setting.noPrompts = true) : (_ctx.options.setting = { noPrompts: true })

            const index = abortPrePageKeys.indexOf(key)
            index > -1 && abortPrePageKeys.splice(index, 1)
          }
        }
        catch (error) {
          console.error('<<onRequestError error>>', error)
        }
      }
    }
    // 处理重复请求
    if (_ctx.error?.message?.match('Failed to fetch')) {
      const { noPrompts = false } = _ctx.options.setting || {}
      !noPrompts && vantDialog({
        title: '数据加载异常',
        message: '网络请求无法正常发送',
      })
    }

    console.error(_ctx.error.name, _ctx.error.message, _ctx.request)
    // && _ctx.options.timeout
    if (_ctx.error.name === 'AbortError' && _ctx.options.timeout && _ctx.error.message.includes('aborted'))
      this.timeoutErrorHandle(_ctx)
  }

  /**
   * 响应拦截器
   * @param _ctx NitroFetchContext
   */
  onResponse = async <R = FetchResponseType<unknown>>(_ctx: NitroFetchContext & { response: FetchResponse<R> }): Promise<unknown> => {
    // console.log('onResponse', _ctx)

    const uuid = this.getHeaderUUID(_ctx.options.headers as Headers)
    const nuxtApp = uuid ? this.getCurrentNuxtApp(uuid) : null
    uuid && this.removeCurrentNuxtApp(uuid)
    if (nuxtApp && nuxtApp && (_ctx.options?.setting?.repetition === 'abort' || this.timeout(_ctx))) {
      const { removePendingRequest } = nuxtApp.$useAbort as ReturnType<typeof useAbort>
      removePendingRequest(_ctx, 'clear')
    }

    // 设置token
    this.setToken(_ctx.response.headers)
    const { status } = _ctx.response
    // 响应数据
    const responseData = _ctx.response._data as FetchResponseType<unknown>
    const { code, msg } = responseData
    // console.log('onResponse', code, msg)
    if (this.localErrorStatus.includes(status) || this.localErrorStatus.includes(code))
      return this.localErrorHandle<R>(status, responseData, _ctx)

    if (this.updateStatus.includes(status) || this.updateStatus.includes(code) || msg?.includes('NoClassDefFoundError'))
      return this.serverUpdateErrorHandle<R>(status, responseData, _ctx)
    // 500
    if (this.errorStatus.includes(status) || this.errorStatus.includes(code) || msg?.includes('服务未找到'))
      return this.serverErrorHandle<R>(status, responseData, _ctx)

    return this.responseHandle<R>(responseData, _ctx)
  }

  /**
   * 响应错误拦截
   * statusCode 不是重定向并且大于 299时触发
   * @param _ctx NitroFetchContext
   */
  onResponseError = async <R = FetchResponseType<unknown>>(_ctx: NitroFetchContext & { response: FetchResponse<R> }): Promise<void> => {
    // 对响应错误进行处理
    // console.error('onResponseError', _ctx)
    const uuid = this.getHeaderUUID(_ctx.options.headers as Headers)
    const nuxtApp = uuid ? this.getCurrentNuxtApp(uuid) : null
    if (nuxtApp && uuid) {
      this.removeCurrentNuxtApp(uuid)
      const { removePendingRequest } = nuxtApp.$useAbort as ReturnType<typeof useAbort>
      removePendingRequest(_ctx, 'clear')
    }
  }

  setToken(headers: Headers) {
    const { USER_TOKEN } = storeToRefs(useAuth())
    // 设置token
    if (headers.has('Authorization') || headers.has('authorization')) {
      this.isReference = false
      USER_TOKEN.value = headers.get('Authorization') || headers.get('authorization') || ''
    }
  }

  setClientHeaders(headers: HeadersInit | undefined): HeadersInit | undefined {
    if (!headers)
      return headers
    try {
      const reqHeader = useRequestHeaders([
        'x-real-ip',
        'x-forwarded-for',
        'x-forwarded-port',
        'x-forwarded-proto',
        'user-agent',
      ])
      headers = {
        ...headers,
        'x-real-ip': reqHeader['x-real-ip'] || '',
        'x-forwarded-for': reqHeader['x-forwarded-for'] || '',
        'x-forwarded-port': reqHeader['x-forwarded-port'] || '',
        'x-forwarded-proto': reqHeader['x-forwarded-proto'] || '',
        'user-agent': reqHeader['user-agent'] || 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/108.0.0.0 Safari/537.36',
      }
    }
    catch (e) {
      // console.error(e)
    }

    return headers
  }

  async responseHandle<R>(responseData: FetchResponseType<unknown>, _ctx: NitroFetchContext & { response: FetchResponse<R> }) {
    const { code, msg } = responseData
    this.showModel.includes(code) && isClient && vantDialog({
      message: msg,
    })

    if (this.successCode.includes(code))
      return responseData

    //  未登录需要登录 TODO: 自动刷新token 暂时无用
    if ([401, 402].includes(responseData.code)) {
      const authStore = useAuth()
      authStore.clearLogin()
      !_ctx.response.url.includes('user/mediaUser/info') && useNoLoginVantDialog()
      responseData.msg = '还未登录，请先登录'
      return Promise.reject(new Error(`${isObject(responseData) ? JSON.stringify({ ...responseData, url: _ctx.request }) : responseData}`))
    }

    //
    // 微信授权登录413 | 未注册411
    if ([411, 412, 413].includes(responseData.code)) {
      const thirdAuthStore = useThird()
      vantToast.clear()
      vantDialog.close()
      if (responseData.code === 411) {
        // 关闭当前登录页面的全部提示
        return this.noRegister(responseData)
      }
      else if (responseData.code === 413) {
        return this.noBindAccount(responseData)
      }
      else {
        thirdAuthStore.setThirdSecret(responseData.data as string)
        return responseData
      }
    }

    // 431 数据重复
    if ([431].includes(responseData.code)) {
      vantToast.clear()
      vantDialog.close()
      vantDialog({
        title: '提示',
        message: msg,
        confirmButtonText: '确定',
      })
      return Promise.reject(new Error(`${isObject(responseData) ? JSON.stringify({ ...responseData, url: _ctx.request }) : responseData}`))
    }
    return Promise.reject(new Error(`${isObject(responseData) ? JSON.stringify({ ...responseData, url: _ctx.request }) : responseData}`))
  }

  localErrorHandle<R>(status: number, responseData: FetchResponseType<unknown>, _ctx: NitroFetchContext & { response: FetchResponse<R> }) {
    const { noPrompts = false } = _ctx.options.setting || {}
    !noPrompts && vantDialog({
      title: '错误',
      message: '网络请求超时',
    })
    return Promise.reject(new Error(`${isObject(responseData) ? JSON.stringify({ ...responseData, url: _ctx.request }) : responseData}`))
  }

  noRegister(responseData: FetchResponseType<any>) {
    return vantDialog.confirm({
      title: '登录提示',
      // 默认错误提示： 当前第三方无绑定任何媒体号 [如注册媒体号请填写手机] 或 [在已有媒体号后台进行绑定添加此第三方]
      message: responseData.msg || '账号不存在',
      cancelButtonText: '手机验证码登录',
      confirmButtonText: '前往注册',
    }).then(() => {
      // 跳转到注册页面
      goAnyPage(registerPath())
      return responseData
    }).catch(() => {
      goAnyPage(loginPath())
      return responseData
    })
  }

  noBindAccount(responseData: FetchResponseType<any>) {
    const thirdAuthStore = useThird()
    return vantDialog.confirm({
      title: '登录提示',
      // 默认错误提示： 当前第三方无绑定任何媒体号 [如注册媒体号请填写手机] 或 [在已有媒体号后台进行绑定添加此第三方]
      message: bindingMessage,
      cancelButtonText: '绑定已有账号',
      confirmButtonText: '注册新账号',
    }).then(() => {
      // 跳转到注册页面
      thirdAuthStore.setThirdSecret(responseData.data as string)
      goAnyPage(registerPath())
      return responseData
    }).catch(() => {
      thirdAuthStore.setThirdSecret(responseData.data as string)
      goAnyPage(loginPath())
      return responseData
    })
  }

  timeoutErrorHandle(_ctx: NitroFetchContext) {
    const { noPrompts = false } = _ctx.options.setting || {}

    if (!noPrompts) {
      vantToast.clear()
      vantDialog.close()
      vantDialog({
        title: '错误',
        message: '请求响应超时，请稍后再试',
      })
    }
    return Promise.reject(createError({ cause: '网络请求超时', message: '请求响应超时，请稍后再试' }))
  }

  serverErrorHandle<R>(status: number, responseData: FetchResponseType<unknown>, _ctx: NitroFetchContext & { response: FetchResponse<R> }) {
    const { noPrompts = false } = _ctx.options.setting || {}
    if (!noPrompts) {
      vantToast.clear()
      vantDialog({
        title: '服务器异常',
        message: '服务器发生了错误,请重试',
      })
    }
    // console.log('serverErrorHandle')
    return Promise.reject(new Error(`${isObject(responseData) ? JSON.stringify({ ...responseData, url: _ctx.request }) : responseData}`))
  }

  serverUpdateErrorHandle<R>(status: number, responseData: FetchResponseType<unknown>, _ctx: NitroFetchContext & { response: FetchResponse<R> }) {
    // console.log('serverUpdateErrorHandle')
    const { noPrompts = false } = _ctx.options.setting || {}
    !noPrompts && vantDialog({
      title: '服务器更新中',
      message: '服务更新中，请稍后再试',
    })
    const error = createError({ cause: '服务器更新中', message: '服务更新中，请稍后再试', data: `${isObject(responseData) ? JSON.stringify({ ...responseData, url: _ctx.request }) : responseData}` })
    return Promise.reject(error)
  }
}
