import type { TimeString } from '@qcwp/common'

export interface ConfigResponse {
  createTime: TimeString
  updateTime: TimeString
  remark: string
  configId: string
  configName: string
  configKey: string
  configValue: string
  configType: string
}
export interface DictResponse {
  dictCode: string
  dictSort: string
  dictLabel: string
  dictValue: string
  cssClass: null | string
  listClass: string
  isDefault: string
}

export interface WechatMpShareConfigResponse {
  appId: string
  nonceStr: string
  signature: string
  timestamp: string
  url: string
}

export interface MpQrcodeParams {
  envVersion: 'release'
  page: string
  scene: string
}
export enum FileType {
  ARTICLE = 'ARTICLE',
  COMPLAIN = 'COMPLAIN',
  OTHER = 'OTHER',
  RICHTEXT = 'RICHTEXT',
  SYS = 'SYS',
  USERINFO = 'USERINFO',
}
