import type { PaginationResponse } from '@qcwp/common'
import type { AddArticleCommentParams, AddArticleCommentResponse, ArticleBannerParams, ArticleBannerResponse, ArticleColumnParams, ArticleColumnResponse, ArticleCommentParams, ArticleCommentResponse, ArticleDetailResponse, ArticleListParams, ArticlePagesResponse, ArticleRecommendParams, ArticleRecommendResponse, ArticleSearchListParams, ArticleTopResponse, BindTopicsParams, BindTopicsResponse, DailyCreateParams, DailyEssenceParams, DailyEssenceResponse, DailyItemData, HotTagsResponse, LiveBarrageListParams, LiveBarrageListResponse, LiveBarrageParams, LiveDetailResponse, LiveInfoResponse, LiveListParams, LiveListResponse, TopicDetailComponentDataParams, TopicDetailComponentDataResponse, TopicDetailResponse, TopicPagesParams, TopicPagesResponse } from './type'
import type { FetchResponseType } from '~~/src/server/request/type'
import { useApiFetch } from '~~/src/server/request'

export async function getArticleSearchList(params: ArticleSearchListParams) {
  return useApiFetch<FetchResponseType<PaginationResponse<ArticlePagesResponse[]>>>('/news/portal/article/search', {
    method: 'GET',
    params,
  })
}
/**
 * 查询文章列表
 * @params ArticleListParams
 * @returns FetchResponseType<PaginationResponse<ArticlePagesResponse>>
 */
export async function getArticleList(params: ArticleListParams) {
  return useApiFetch<FetchResponseType<PaginationResponse<ArticlePagesResponse[]>>>('/news/portal/article/list', {
    method: 'GET',
    params,
  })
}

/**
 * 查询文章列表
 * @params ArticleListParams
 * @returns FetchResponseType<PaginationResponse<ArticlePagesResponse>>
 */
export async function hotArticleApi(params: { size: number }) {
  return useApiFetch<FetchResponseType<ArticlePagesResponse[]>>('/news/portal/article/hotArticle', {
    method: 'GET',
    params,
  })
}

/**
 * 查询推荐位文章列表
 * @params ArticleRecommendParams
 * @returns FetchResponseType<PaginationResponse<ArticleRecommendResponse>>
 */
export async function getArticleRecommend(params: ArticleRecommendParams) {
  return useApiFetch<FetchResponseType<PaginationResponse<ArticleRecommendResponse[]>>>('/news/portal/article/recomTitle', {
    method: 'GET',
    params,
  })
}
/**
 * 查询推荐文章
 * @param params
 * @returns
 */
export async function getArticleRecommendList(params: ArticleListParams) {
  return useApiFetch<FetchResponseType<PaginationResponse<ArticlePagesResponse[]>>>('/news/portal/article/recomArticle', {
    method: 'GET',
    params,
  })
}

/**
 * 查询推荐文章
 * @param params
 * @returns
 */
export async function getArticleColumnList(params: ArticleRecommendParams) {
  return useApiFetch<FetchResponseType<PaginationResponse<ArticlePagesResponse[]>>>('/news/portal/article/columnArticle', {
    method: 'GET',
    params,
  })
}

/**
 * 查询车系文章列表
 */
export async function getArticleSeriesList(params: ArticleListParams) {
  return useApiFetch<FetchResponseType<PaginationResponse<ArticlePagesResponse[]>>>('/news/portal/seriesArticle/list', {
    method: 'GET',
    params,
  })
}
/**
 * 查询车系栏目文章列表
 */
export async function getArticleColumnSeriesList(params: ArticleListParams) {
  return useApiFetch<FetchResponseType<PaginationResponse<ArticlePagesResponse[]>>>('/news/portal/seriesArticle/columnArticle', {
    method: 'GET',
    params,
  })
}

/**
 * 查询文章排行榜列表
 * @params ArticleTopParams
 * @returns FetchResponseType<PaginationResponse<ArticleTopResponse>>
 */
export async function getArticleTop() {
  return useApiFetch<FetchResponseType<ArticleTopResponse>>('/news/portal/article/topList', {
    method: 'GET',
  })
}

/**
 * 获取文章详情
 * @param articleId string
 * @returns AsyncData<FetchResponseType<PaginationResponse<ArticleTopResponse>>>
 */
export async function getArticleDetail(articleId: string) {
  return useApiFetch<FetchResponseType<ArticleDetailResponse>>(`/news/portal/article/${articleId}`, {
    method: 'GET',
  })
}

export async function getUserArticleDetail(articleId: string) {
  return useApiFetch<FetchResponseType<ArticleDetailResponse>>(`/news/user/article/${articleId}`, {
    method: 'GET',
  })
}

/**
 * 查询资讯栏目列表
 * @params  ArticleCommentParams
 * @returns FetchResponseType<PaginationResponse<ArticleCommentResponse>>
 */
export async function getArticleColumn(params: ArticleColumnParams) {
  return useApiFetch<FetchResponseType<ArticleColumnResponse[]>>('/news/portal/column/all', {
    method: 'GET',
    params: { ...params },
    setting: {
      repetition: 'pre',
      noPrompts: true,
    },
  })
}

/**
 * 查询资讯栏目列表
 * @params  ArticleCommentParams
 * @returns FetchResponseType<PaginationResponse<ArticleCommentResponse>>
 */
export async function getArticleBanner(params: ArticleBannerParams) {
  return useApiFetch<FetchResponseType<ArticleBannerResponse[]>>('/news/portal/banner/list', {
    method: 'GET',
    params,
  })
}

/**
 * 查询文章评论列表
 * @params  ArticleCommentParams
 * @returns FetchResponseType<PaginationResponse<ArticleCommentResponse>>
 */
export async function getArticleComment(params: ArticleCommentParams) {
  return useApiFetch<FetchResponseType<PaginationResponse<ArticleCommentResponse[]>>>('/news/portal/articleComment/list', {
    method: 'GET',
    params: { ...params, version: '2.0' },
  })
}

/**
 * 新增文章评论
 * @params  AddArticleCommentParams
 * @returns FetchResponseType<boolean>
 */
export async function addArticleComment(params: AddArticleCommentParams) {
  return useApiFetch<FetchResponseType<AddArticleCommentResponse>>('/news/user/articleComment', {
    method: 'POST',
    body: params,
  })
}

/**
 * 删除文章评论
 * @param commentId  string
 * @returns FetchResponseType<boolean>
 */
export async function deleteArticleComment(commentId: string) {
  return useApiFetch<FetchResponseType<boolean>>(`/news/user/articleComment/${commentId}`, {
    method: 'DELETE',
  })
}

/**
 * 统计:点击查看文章
 * @params  新增文章评论
 * @returns FetchResponseType<boolean>
 */
export async function AddArticleLookCount(articleId: string) {
  return useApiFetch<FetchResponseType<boolean>>(`/news/portal/article/look/${articleId}`, {
    method: 'POST',
    setting: {
      noPrompts: true,
    },
  })
}
/**
 * 收藏-取消
 */
export async function articleCollection(articleId: string) {
  return useApiFetch<FetchResponseType<boolean>>('/news/user/collect', {
    method: 'PUT',
    params: { articleId },
  })
}
/**
 * 收藏-取消
 */
export async function userMediaAttention(focusMediaUserId: string) {
  return useApiFetch<FetchResponseType<boolean>>('/news/user/focus', {
    method: 'PUT',
    params: { focusMediaUserId },
  })
}

/**
 * 热门标签列表
 * @returns FetchResponseType<boolean>
 */
export async function getHotTags() {
  return useApiFetch<FetchResponseType<HotTagsResponse[]>>('/news/portal/tags/list', {
    method: 'GET',
  })
}

/**
 * 查询专题列表
 */
export async function getTopicPages(params?: TopicPagesParams) {
  return useApiFetch<FetchResponseType<PaginationResponse<TopicPagesResponse[]>>>('/news/portal/topic/list', {
    method: 'GET',
    params,
  })
}
/**
 * 获取专题详情
 * @param id string
 * @returns
 */
export async function getTopicDetail(id: string) {
  return useApiFetch<FetchResponseType<TopicDetailResponse>>(`/news/portal/topic/${id}`, {
    method: 'GET',
  })
}

/**
 * 获取专题详情
 * @param topicId string
 * @param topicComponentType TOPIC_COMPONENT_TYPE
 * @returns
 */
export async function getTopicDetailComponentData(topicId: string, params: TopicDetailComponentDataParams) {
  return useApiFetch<FetchResponseType<TopicDetailComponentDataResponse>>('/news/portal/topicData/list', {
    method: 'GET',
    params: {
      topicId,
      ...params,
    },
  })
}

/**
 * 获取专题详情
 * @param topicId string
 * @param topicComponentType TOPIC_COMPONENT_TYPE
 * @returns
 */
export async function getBindTopics(params: BindTopicsParams) {
  return useApiFetch<FetchResponseType<BindTopicsResponse[]>>('/news/portal/topicData/bindTopic', {
    method: 'GET',
    params,
  })
}

/**
 * 直播详情
 */
export async function getLiveDetail(liveId: string) {
  return useApiFetch<FetchResponseType<LiveDetailResponse>>(`/news/portal/live/${liveId}`, {
    method: 'GET',
  })
}
export async function getLiveBarrageList(params: LiveBarrageListParams) {
  return useApiFetch<FetchResponseType<LiveBarrageListResponse[]>>(`/news/portal/liveDanmu/pollFetchList?${qs.stringify(params)}`, {
    method: 'GET',
    setting: {
      noPrompts: true,
      repetition: 'abort',
    },
  })
}
export async function getLiveInfo(liveId: string) {
  return useApiFetch<FetchResponseType<LiveInfoResponse>>(`/news/portal/live/data/${liveId}`, {
    method: 'GET',
    setting: {
      noPrompts: true,
      repetition: 'abort',
    },
  })
}
export async function addLiveBarrage(params: LiveBarrageParams) {
  return useApiFetch<FetchResponseType<string>>('/news/user/liveDanmu', {
    method: 'POST',
    body: { ...params },
  })
}
export async function updateLiveLike(liveId: string, count: number) {
  return useApiFetch<FetchResponseType<boolean>>(`/news/portal/live/like/${liveId}`, {
    method: 'PUT',
    params: { partLikeTotal: count },
  })
}
export async function liveLookCount(liveId: string) {
  return useApiFetch<FetchResponseType<boolean>>(`/news/portal/live/look/${liveId}`, {
    method: 'PUT',
  })
}
/**
 * 用户初看，才调接口
 */
export async function liveUserLookCount(liveId: string) {
  return useApiFetch<FetchResponseType<boolean>>(`/news/user/live/look/${liveId}`, {
    method: 'PUT',
    setting: {
      noPrompts: true,
    },
  })
}
/**
 * 用户未赞，才调接口
 */
export async function updateUserLiveLike(liveId: string, count: number) {
  return useApiFetch<FetchResponseType<boolean>>(`/news/user/live/like/${liveId}`, {
    method: 'PUT',
    params: { partLikeTotal: count },
  })
}

export async function getLiveList(params: LiveListParams) {
  return useApiFetch<FetchResponseType<PaginationResponse<LiveListResponse[]>>>('/news/portal/live/list', {
    method: 'GET',
    params,
  })
}

/**
 * 每天精选
 */
export async function getDailyList(params: DailyEssenceParams) {
  return useApiFetch<FetchResponseType<PaginationResponse<DailyEssenceResponse[]>>>('/news/portal/essence/list', {
    method: 'GET',
    params,
  })
}

export async function getDailyDetail(id?: string) {
  const params: Record<string, any> = {}
  if (id)
    params.id = id

  return useApiFetch<FetchResponseType<DailyEssenceResponse>>('/news/portal/essence', {
    method: 'GET',
    params,
  })
}

export async function createDaily(params: DailyCreateParams) {
  return useApiFetch<FetchResponseType<boolean>>('/news/user/essence', {
    method: 'POST',
    body: { ...params },
  })
}

export async function addDailyData(params: DailyItemData) {
  return useApiFetch<FetchResponseType<boolean>>('/news/user/essenceDataRef', {
    method: 'POST',
    body: { ...params },
  })
}

export async function updateDailyData(params: DailyItemData[]) {
  return useApiFetch<FetchResponseType<boolean>>('/news/user/essenceDataRef', {
    method: 'PUT',
    body: params,
  })
}

export async function deleteDailyData(params: string[]) {
  return useApiFetch<FetchResponseType<boolean>>('/news/user/essenceDataRef', {
    method: 'DELETE',
    body: params,
  })
}
