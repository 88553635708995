import type { PaginationParams, TimeString } from '@qcwp/common'

export interface CarBrandParams {
  brandName?: string // 品牌名
  brandNameInitial?: string // 品牌名首字母大写
  coverImg?: string // 封面图
  englishName?: string // 英文名
  firstEnglishSortEnum?: string // 首个英文：DESC降序/ASC升序,可用值:ASC,DESC
  id?: string // 编号
  isHot?: 0 | 1 // 是否热门
  recomm?: '0' | '1' // 是否推荐
  oldId?: string // 旧品牌id
  remark?: string // 备注
  sohuCarBrandId?: string // 搜狐汽车品牌id
  sort?: string // 排序
}

export interface searchSeriesItem {
  vendorSeriesName: string
  seriesId: string
}

export interface searchSeries {
  bodyStructureLs: string
  carBrandLogo: string
  carSeriesEnergyCode: string
  id: number
  maxPrice: number
  minPrice: number
  modelList: ModelList[]
  overallScore: number
  seriesGrade: string
  vendorSeriesName: string
}
interface ModelList {
  baseDisplacement: string
  carModelName: string
  enduranceMileage: string
  id: number
  vendorGuidePrice: number
}

export interface BrandResponse {
  brandName: string
  brandNameInitial: string
  coverImg: string
  createTime: string
  englishName: string | null
  id: string
  isHot: 0 | 1
  recomm?: '0' | '1' // 是否推荐
  remark: string
  sort: number | null
}
export interface SeriesAllResponse {
  id: string
  carSeriesName: string
  carBrandName?: string
}
export interface VendorResponse {
  carVendorName: string
  carVendorId: string
  id: string
  carSeriesList: VenderSeriesResponse[]
}

export interface VenderSeriesResponse {
  carSeriesEnergyCode: string
  carSeriesName: string
  coverImg: string | null
  id: string
  maxPrice: number
  minPrice: number
  seriesGrade: string // 车系级别：小型车、中型车、中大型车..
  sellStatus?: string // 停产、未上市、在售、新上市
}
export interface SeriesResponse {
  id: string
  carSeriesName: string
  carBrandId: string
  carBrandName: string
  carVendorId: string
  carVendorName: string
  carBrandVendorId: string
  productionPlace: string // 产地：国产/进口
  seriesGrade: string // 车系级别：小型车、中型车、中大型车..
  sellStatus: string // 停产、未上市、在售、新上市
  carSeriesEnergyCode: string
  maxPrice: number
  minPrice: number
  coverImg: string | null
  merit: string | null
  defect: string | null
  displacementScope: string | null
  officialFuel: string | null
  maxFuel: string | null
  minFuel: string | null
  avgFuel: string | null
  isNewEnergy: string | null
  bodyStructureLs: string | null
  gearboxLs: string | null
  newEnduranceMileage: string | null
  newMotor: string | null
  carBrandLogo: string | null
  modelTotal: string | null
}

export interface FilterSeriesParams extends PaginationParams {
  maxFuel?: string // 最大油耗
  minFuel?: string // 最小油耗
  avgFuel?: string // 平均油耗

  carBrandId?: string // 品牌id
  carBrandName?: string // 品牌名称
  carBrandVendorId?: string // 汽车品牌厂商中间id
  carSeriesName?: string // 车系名称
  carVendorId?: string // 厂商id
  carVendorName?: string // 厂商名称

  carSeriesEnergyCode?: string // 车系能源方式code
  bodyStructureLs?: string // 车身结构：两厢轿车...
  defect?: string // 缺点
  merit?: string // 优点
  displacementScope?: string // 排量范围
  displacementMax?: number // 排量最大值
  displacementMin?: number // 排量最小值
  gearboxLs?: string // 变速箱：手自一体，双离合
  sellStatus?: string // 售卖状态：停产、未上市、在售
  seriesGrade?: string // 车系级别：小型车、中型车、中大型车...
  maxPrice?: string // 车系最大价格
  minPrice?: string // 车系最小价格
  productionPlace?: string // 产地：合资/国产/进口

  hot?: '0' | '1' // 是否热门：0否，1是
  recomm?: '0' | '1' // 是否推荐：0否，1是
  isNewEnergy?: 0 | 1 // 是否新能源
}

export interface SeriesDetailResponse {
  id: string
  createTime: TimeString
  carSeriesName: string
  carBrandId: string
  carBrandName: string
  carVendorId: string
  carVendorName: string
  carBrandVendorId: string
  productionPlace: string
  seriesGrade: string
  sellStatus: string
  carSeriesEnergyCode: string
  minPrice: number | null
  maxPrice: number | null
  coverImg: string | null
  merit: string | null
  defect: string | null
  displacementScope: string | null
  officialFuel: string | null
  maxFuel: string | null
  minFuel: string | null
  avgFuel: string | null
  isNewEnergy?: 0 | 1 // 是否新能源
  bodyStructureLs: string | null
  gearboxLs: string | null
  newEnduranceMileage: string | null
  newMotor: string | null
  modelTotal: string
  carBrandLogo: string | null
}

export interface ModelAllParams {
  carSeriesId: string
}
/**
 * 查询车型全部参数参数
 * 根据车型ID查全部参数/根据车系ID和车型年款查全部车型参数
 */
export interface ModelFieldParams {
  carModelId?: string
  carSeriesId?: string
  modelYear?: string
}
export interface ModelFieldResponse {
  carModelName: string
  id: string
  year: string
  vendorGuidePrice: string | null
  fieldValueVOList: FieldValueVO[]
}
export interface FieldValueVO {
  carFieldId: string // 字段id integer
  carFieldTypeId: string | null // 字段类型id integer
  carFieldValue: string // 字段value string
  carModelId: string // 车型id integer
  id: string
}

export enum SellStatusCode {
  NORMAL = 1,
  STOP = 2,
}
export interface ModelResponse {
  avgFuel: string
  baseDisplacement: string
  bodyStructure: string
  carBrandId: string
  carBrandName: string
  carBrandVendorId: string
  carModelEnergyCode: string
  carModelName: string
  carSeriesId: string
  carSeriesName: string
  carVendorId: string
  carVendorName: string
  coverImg: string | null
  dealerMinPrice: string
  driveMode: string
  enduranceMileage: string
  energy: string
  engine: string
  engineParameter: string
  gearbox: string
  grade: string
  id: string
  maxFuel: string
  minFuel: string
  officialFuel: string
  /**
   * @deprecated
   */
  sellLabel: string
  sellStatusCode: SellStatusCode // 售卖状态：1=在售,2=停产
  synthesizeFuel: string
  umweltsatz: string
  vendorGuidePrice: string
  year: string
}

/**
 * 汽车字段类型
 */
export interface CarFieldTypeResponse {
  carFieldTypeName: string // 字段类型名称
  id: string // 编号
  remark: string // 备注
  sort: string // 字段类型排序
}
/**
 * 汽车字段
 */
export interface CarFieldResponse {
  carFieldLink: string // 字段链接
  carFieldName: string // 字段名称
  carFieldTypeId: string // 汽车字段类型id
  id: string // 编号
}
export interface OperationModelParams extends PaginationParams {
  seriesId: string
}

export interface DealerAllListParams {
  address?: string
  cityId?: string
  cityName?: string
  dealerIntro?: string
  dealerLinkman?: string
  dealerLogo?: string
  dealerName?: string
  dealerNameSimple?: string
  dealerPhone?: string
  email?: string
  existBindMedia?: boolean
  id?: string
  majorCarName?: string
  mediaUserId?: string
  provinceId?: string
  provinceName?: string
  remark?: string

  carSeriesId?: string
}
export interface DealerListParams extends PaginationParams, DealerAllListParams {

}

export interface DealerListResponse {
  address: string
  cityId: string
  cityName: string
  createTime: string
  dealerIntro: string
  dealerLinkman: string
  dealerLogo: string
  dealerName: string
  dealerNameSimple: string
  dealerPhone: string
  email: string
  id: string
  majorCarName: string
  provinceId: string
  provinceName: string
  remark: string
}
export interface DealerDetailResponse extends DealerListResponse {
  shopImgs?: string
  endOpenTime?: TimeString
  startOpenTime?: TimeString
  existBindMedia: boolean
  mediaUserId?: string
}
export interface DealerSalesParams extends PaginationParams {
  carDealerId: string
}
export interface DealerSalesResponse {
  carDealerId: string
  createTime: TimeString
  id: string
  remark: string
  staffJob: string
  staffName: string
  staffPhone: string
  staffProfile: string // 头像
}

export interface DealerModelParams extends PaginationParams {
  carDealerId: string
  carModelId?: string
  carSeriesId?: string
  isHot?: boolean
  isMainRecomm?: boolean // 是否主推
  isPromotion?: boolean // 是否促销
}
export interface DealerModelResponse {
  createTime: TimeString
  updateTime: TimeString | null
  carDealerId: string
  carModelId: string
  carSeriesId: string
  vendorPrice: string | null
  ourPrice: string | null
  deprPrice: string | null
  addPrice: string | null
  promotionTime: string | null
  isPromotion: boolean
  isHot: boolean
  isMainRecomm: string | null
  modelVO: {
    id: string
    createTime: TimeString
    carModelName: string
    carSeriesId: string
    carSeriesName: string
    carBrandId: string
    carBrandName: string
    carVendorId: string
    carVendorName: string
    carBrandVendorId: 3
    carModelEnergyCode: string | null
    sellStatusCode: 2
    /**
     * @deprecated
     */
    sellLabel: string | null
    baseDisplacement: string
    coverImg: string | null
    year: string
    gearbox: string
    engine: string | null
    bodyStructure?: string
    officialFuel?: string
    maxFuel?: string
    minFuel?: string
    avgFuel?: string
    vendorGuidePrice: string
    dealerMinPrice?: string
    energy?: string
    engineParameter?: string
    driveMode: string
    enduranceMileage?: string
    grade: string
    synthesizeFuel?: string
    umweltsatz: string
  }
}
export interface DealerSeriesAllParams {
  carDealerId: string
}
export interface DealerSeriesParams extends PaginationParams, DealerSeriesAllParams {
  carSeriesId?: string
}
export interface DealerSeriesResponse {
  createTime: TimeString
  carDealerId: string
  seriesVO: {
    id: string
    createTime: TimeString
    carSeriesName: string
    productionPlace: string
    seriesGrade: string
    carSeriesEnergyCode: string
    sellStatus: string | null
    coverImg?: string
    minPrice: number
    maxPrice: number
  }
}

export interface DealerSeriesAllResponse {
  carBrandId: string
  carBrandName: string
  carBrandVendorId: string
  carDealerId: string
  carSeriesId: string
  carSeriesName: string
  carVendorName: string
  createTime: TimeString
  id: string
  remark: string
  sellStatusCodeCount: number
}

export interface DealerAddXjSjParams {
  carDealerId?: string
  carDealerIds?: string[]
  carBrandId?: string
  carBrandName?: string
  carVendorId?: string
  carVendorName?: string
  carSeriesId?: string
  carSeriesName?: string
  carModelId?: string
  carModelName?: string
  customTargetModel?: string
  carModelNakedPrice?: string // 车型裸车价
  enquiryTestdriveType?: '1' | '2' // 1=询价,2=试驾
  provinceId?: string
  cityId?: string
  remark?: string
  userName?: string
  userPhone: string
  userSex?: string
  testdriveInviteId?: string
  testdriveTimeWithin?: string // 时间内试驾（一周内、半个月内、3个月内）
  drivingLicenseFrontImg?: string // 驾驶证正面照
}
export interface SeriesPraiseSelectionResponse {
  carBrandId: string //  品牌id
  carBrandName: string //  品牌名称
  carSeriesId: string //  车系id\
  carSeriesName: number //  string
  overallScore: number //  综合评分
  defect: string //  缺点
  merit: string //  缺点
  netFriendMaxBuyPrice: string //  网友最大购车价
  netFriendMinBuyPrice: string //  网友最小购车价
}
export interface SeriesPraiseResponse {
  carBrandId: string //  品牌id
  carBrandName: string //  品牌名称
  carSeriesId: string //  车系id\
  carSeriesName: number //  string
  appearanceScore: number //  外观评分
  comfortScore: number //  舒适评分
  controlScore: number //  操控评分
  costScore: number //  性价评分
  electricConsumeScore: number //  电耗评分
  fuelOnsumeScore: number //  油耗评分
  interiorScore: number //  内饰评分
  netFriendMaxBuyPrice: string //  网友最大购车价
  netFriendMinBuyPrice: string //  网友最小购车价
  overallScore: number //  综合评分
  powerScore: number //  动力评分
  serviceScore: number //  售前售后
  spaceScore: number // 空间评分
}

// 1=综合评分 2=外观评分 3=空间评分 4=内饰评分 5=动力评分 6=操控评分 7=油耗评分 8=舒适评分 9=性价评分 10=售前售后
export type PraiseSortType = '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10'
// 1=短口碑,2=最满意,3=最不满意,4=外观,5=内饰,6=空间,7=动力,8=操控,9=油耗,10=舒适性,11=性价比, 12=售前售后
export type PraiseType = '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12'
export interface SeriesPraiseDetailResponse extends SeriesPraiseResponse {
  modelPraiseCommentCountsVO: { commentCount: number; praiseType: PraiseType }[]
}
export interface SeriesPraiseParams extends PaginationParams {
  carSeriesId?: string
  seriesGrade?: string
  sortOrder?: PraiseSortType
}

export interface ModelPraiseParams extends PaginationParams {
  carModelId?: string
  carSeriesId?: string
  content?: string
  id?: string
  isSync?: boolean
  praiseType?: PraiseType
}

export interface ModelPraiseResponse {
  id: string
  createTime: TimeString
  praiseType: PraiseType
  content: string
  avgQtrip: number // 平均油耗
  nakedCarPrice: number
  carModelId: string
  carModelName: string
}

export interface SimplePraiseParams {
  avgQtrip?: string
  carModelId: string
  carSeriesId: string
  content: string
  nakedCarPrice: string
  praiseType: PraiseType
}

export interface TestDriveResponse {
  carBrandId: string // 汽车品牌ID integer(int64)
  carBrandName: string // 汽车品牌名 string
  carVendorId: string
  carVendorName: string
  carSeriesId: string // 汽车车系ID integer(int64)
  carSeriesName: string // 汽车车系名 string
  carModelId: string // 汽车车型ID integer(int64)
  carModelName: string // 汽车车型名 string
  coverUrl: string // 封面地址 string
  createTime: string // 创建时间 string(date-time)
  id: string // 编号 integer(int64)
  publishStatus: '0' | '1' // 0=下架状态,1=上架状态 string
  remark: string // 备注
  title: string // 标题
  introduce: string // 介绍
}

export interface TestDriveApplyListResponse {
  id: string
  createTime: TimeString
  testdriveTimeWithin: TimeString
  enquiryTestdriveType: '1' | '2'
  carBrandName: string
  carVendorName: string
  carSeriesName: string
  carModelName: string | null
  carSeriesId: string
  carModelId: string | null
  carModelNakedPrice: string | null
  provinceId: string
  cityId: string
  userName: string
  userSex: '1' | '2'
  userPhone: string
  finishStatus: '0' | '1'
  dealerName: string
  remark: string
}
